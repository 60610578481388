.code-input .react-code-input {
  width: 100%;
  text-align: center;
  margin: 10px 0;
}

.code-input .react-code-input input {
  color: #d70f64 !important;
  font-size: 20px !important;
  text-align: center !important;
}
